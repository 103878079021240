import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText } from '@mui/material';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  texterror: {
    color: 'red',
  },

  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [isRemember, setIsRemember] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const { authUser } = useSelector(({ auth }) => auth);
  const onSubmit = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogin({ email, password, isRemember }));
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setIsEnterPressed(true);
    }
  };

  useEffect(() => {
    if (isEnterPressed) {
      onSubmit(); // Call the login function when Enter key is pressed
      setIsEnterPressed(false); // Reset the state
    }

    // Add event listener to listen for keydown events
    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isEnterPressed]);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const validateEmail = val => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
      setEmail(val);
    }
  };

  const validatePass = val => {
    let regPassword = /^\s+$|^$/gi;
    if (regPassword.test(val)) {
      setPassErr(true);
    } else {
      setPassErr(false);
      setPassword(val);
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/focus-logo/focus-whitebg.jpg'} width={'200px'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              error={emailErr}
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => validateEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              helperText={emailErr ? 'Enter valid email address' : ''}
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            {/* <TextField
              error={passErr}
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              // onChange={event => setPassword(event.target.value)}
              onChange={event => validatePass(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              helperText={passErr ? 'Enter your password' : ''}
              className={classes.textFieldRoot}
            /> */}
            <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                <IntlMessages id="appModule.password" />
              </InputLabel>
              <OutlinedInput
                error={passErr}
                fullWidth
                onChange={event => validatePass(event.target.value)}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                helperText={passErr ? 'Enter your password' : ''}
                className={classes.textFieldRoot}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText id="component-error-text" className="error-password">
                {passErr ? 'Enter your password' : ''}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" onChange={() => setIsRemember(preV => !preV)} />}
              label="Remember me"
            />
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                <IntlMessages id="appModule.forgotPassword" />
              </NavLink>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>

            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/signup">
                <IntlMessages id="signIn.signUp" />
              </NavLink>
            </Box>
          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
