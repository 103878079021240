import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink, useHistory } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText } from '@mui/material';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [passConfirmErr, setConfirmPassErr] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = () => {
    dispatch(
      AuhMethods[CurrentAuthMethod].onRegister({
        email,
        password,
        confirmPassword,
        history,
      }),
    );
  };

  const validateEmail = val => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
      setEmail(val);
    }
  };

  const validatePass = val => {
    // let regPassword = /^\s+$|^$/gi; //empty space
    let regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //min 8 charector
    if (!regPassword.test(val) && password !== confirmPassword) {
      setPassErr(true);
    } else {
      setPassErr(false);
      setPassword(val);
    }
  };
  const validateConfirmPass = val => {
    let regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //min 8 charector
    // let regPassword = /^(?=.*\d).{8,}$/; //min 8 charector
    if (!regPassword.test(val) && password !== confirmPassword) {
      setConfirmPassErr(true);
    } else {
      setConfirmPassErr(false);
      setConfirmPassword(val);
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/sign-up-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7} style={{ textAlign: 'center' }}>
          {/* <CmtImage src={'/images/logo.png'} /> */}
          <CmtImage src={'/images/focus-logo/focus-whitebg.jpg'} width={'200px'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Create an account
        </Typography>
        <form>
          {/* <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.name" />}
              fullWidth
              onChange={event => setName(event.target.value)}
              defaultValue={name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box> */}
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              error={emailErr}
              fullWidth
              onChange={event => validateEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              helperText={emailErr ? 'Enter valid email address' : ''}
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            {/* <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              error={passErr}
              fullWidth
              onChange={event => validatePass(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              helperText={
                passErr ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors' : ''
              }
              className={classes.textFieldRoot}
            /> */}
            <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                <IntlMessages id="appModule.password" />
              </InputLabel>
              <OutlinedInput
                error={passErr}
                fullWidth
                onChange={event => validatePass(event.target.value)}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                helperText={passErr ? 'Enter your password' : ''}
                className={classes.textFieldRoot}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={<IntlMessages id="appModule.password" />}
              />
              <FormHelperText id="component-error-text" className="error-password">
                {passErr ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors' : ''}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box mb={2}>
            {/* <TextField
              type="password"
              label={<IntlMessages id="appModule.confirmPassword" />}
              error={passConfirmErr || password !== confirmPassword}
              fullWidth
              onChange={event => validateConfirmPass(event.target.value)}
              defaultValue={confirmPassword}
              margin="normal"
              variant="outlined"
              helperText={
                passConfirmErr
                  ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                  : password !== confirmPassword
                  ? 'Password & Confirm Password does not match'
                  : ''
              }
              className={classes.textFieldRoot}
            /> */}
            <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                <IntlMessages id="appModule.confirmPassword" />
              </InputLabel>
              <OutlinedInput
                error={passConfirmErr || password !== confirmPassword}
                fullWidth
                onChange={event => validateConfirmPass(event.target.value)}
                defaultValue={confirmPassword}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={<IntlMessages id="appModule.confirmPassword" />}
              />
              <FormHelperText id="component-error-text" className="error-password">
                {passConfirmErr
                  ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                  : password !== confirmPassword
                  ? 'Password & Confirm Password does not match'
                  : ''}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                disabled={email && password && confirmPassword && password === confirmPassword ? false : true}>
                <IntlMessages id="appModule.register" />
              </Button>
            </Box>

            <Typography className={classes.alrTextRoot}>
              <NavLink to="/signin">
                <IntlMessages id="signUp.alreadyMember" />
              </NavLink>
            </Typography>
          </Box>
        </form>

        <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <Typography className={classes.textAcc}>
          Have an account?
          <NavLink to="/signin">Sign In</NavLink>
        </Typography>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
